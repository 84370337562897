
import {message} from 'ant-design-vue'

var appinst = null;

const ismobile = function() {
    var __result = navigator.userAgent
        .match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return __result ? true : false;
}

const exit = function(appcontext) {
    var context = appcontext?appcontext:appinst
    context.config.globalProperties.$vusername = ""
    context.config.globalProperties.$vtoken = ""
    context.config.globalProperties.$storage.remove(context.config.globalProperties.$storagekey_fep_vsignname)
    context.config.globalProperties.$storage.remove(context.config.globalProperties.$storagekey_fep_vtoken)

    context.config.globalProperties.$uimenuselectedkey = "3"
    location.href="#/"
}

const getuserid = function() {
    appinst.config.globalProperties.$vuid = 
        appinst.config.globalProperties.$storage.get(
            appinst.config.globalProperties.$storagekey_fep_vuid
        ).value
    return appinst.config.globalProperties.$vuid
}
const getsoundmode = function(){
    appinst.config.globalProperties.$vsoundmode =
        appinst.config.globalProperties.$storage.get(
            appinst.config.globalProperties.$storagekey_fep_soundmode
        ).value
    if(appinst.config.globalProperties.$vsoundmode == null)
        appinst.config.globalProperties.$vsoundmode = false
    return appinst.config.globalProperties.$vsoundmode
}
const setsoundmode = function(mode){
    appinst.config.globalProperties.$storage.set(
        appinst.config.globalProperties.$storagekey_fep_soundmode,
        mode?true:false)
}

const getusubscrid = function() {
    appinst.config.globalProperties.$vusubscrid = 
        appinst.config.globalProperties.$storage.get(
            appinst.config.globalProperties.$storagekey_fep_vusubscrid
        ).value
    return appinst.config.globalProperties.$vusubscrid
}

const getusername = function() {
    appinst.config.globalProperties.$vusername = 
        appinst.config.globalProperties.$storage.get(
            appinst.config.globalProperties.$storagekey_fep_vsignname
        ).value
    return appinst.config.globalProperties.$vusername
}

const getauthheaders = function() {
    appinst.config.globalProperties.$vtoken = 
        appinst.config.globalProperties.$storage.get(
            appinst.config.globalProperties.$storagekey_fep_vtoken
        ).value
    return {Authorization:`Bearer ${appinst.config.globalProperties.$vtoken}`}
} 


const axiosErrorHandle = function(error, defcallback) {
    
    if(error.code == "ERR_NETWORK"){
        message.error(appinst.config.globalProperties.$langsmgr('langkey.sysmsg.errnetwork'))
        location.href='#/'
    } else if(error.response && error.response.status == 401) {
        message.warning(appinst.config.globalProperties.$langsmgr('langkey.sysmsg.sessionexpired'))
        setTimeout(()=>{location.href='#/'},1000)
    } else if(defcallback && typeof defcallback == "function"){
        defcallback(error)
    }
}

const querystring = function(key){
    const after = window.location.hash.split('?',2)[1]
    if(after){
        const reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)')
        const r = after.match(reg)
        return r!=null ? decodeURIComponent(r[2]) : null
    }
}



export default {
    install: (app) => {
        appinst = app

        //#region customize global properties

        app.config.globalProperties.$siteurl = 
            "https://hajimi.icu"
            //"http://localhost:8080"
        app.config.globalProperties.$backendbase = 
            "https://api.hajimi.icu"
            //"http://127.0.0.1:5001"
        app.config.globalProperties.$docspath = 
            "https://hajimi.icu"
            //"http://localhost:8080"
        app.config.globalProperties.$subscruribase = "http://s.hajimi.icu"
        app.config.globalProperties.$sitename = "哈基米"
        app.config.globalProperties.$shortsitename = "哈基米"
        app.config.globalProperties.$siteversion = "v1.2.1.10"
        app.config.globalProperties.$subtitle = "高速解锁流媒体，纯流量计费使用"
        app.config.globalProperties.$shortsubtitle = "纯流量计费"
        app.config.globalProperties.$telegramgroup = "https://t.me/hajimigroup"
        app.config.globalProperties.$loginbgname = "loginbg.jpg"

        app.config.globalProperties.$storagekey_fep_vuid = "fep_vuid"
        app.config.globalProperties.$storagekey_fep_vusubscrid = "fep_vusubscrid"
        app.config.globalProperties.$storagekey_fep_vsignname = "fep_vsignname"
        app.config.globalProperties.$storagekey_fep_vtoken = "fep_vtoken"
        app.config.globalProperties.$storagekey_fep_soundmode = "fep_soundmode"
        


        app.config.globalProperties.$vuid = ""
        app.config.globalProperties.$vusername = ""
        app.config.globalProperties.$vusubscrid = ""
        app.config.globalProperties.$vtoken = ""
        app.config.globalProperties.$vlangcode = "zhcn"
        app.config.globalProperties.$vsoundmode = true

        app.config.globalProperties.$recaptchav3sitekey = "6LcQbu8oAAAAAPE4jfpgM4XJT6nhZ9p7Haab0r_9"
        app.config.globalProperties.$uimenuselectedkey = "3"
        app.config.globalProperties.$uimenucollapsed = false
        app.config.globalProperties.$menumappaths = {
            "0": "/",
            "1": "/login",
            "2": "/register",
            "3": "/dashboard",
            "4": "/profile",
            "5": "/orders",
            "6": "/order",
            "7": "/financess" ,
            "8": "/flowstats" ,
            "9": "/services" ,
            "10": "/plans" ,
            "11": "/plan" ,
            "12": "/activities" ,
            "13": "/tickets" ,
            "14": "/tos",
            "15": "/documents",
            "16": "/invite",
            "17": "/myroutes"
        }


        //#endregion

        app.config.globalProperties["$ismobile"] = ismobile
        app.config.globalProperties["$axiosErrorHandle"] = axiosErrorHandle
        app.config.globalProperties["$getauthheaders"] = getauthheaders
        app.config.globalProperties["$getuserid"] = getuserid
        app.config.globalProperties["$getusubscrid"] = getusubscrid
        app.config.globalProperties["$getusername"] = getusername
        app.config.globalProperties["$getsoundmode"] = getsoundmode
        app.config.globalProperties["$setsoundmode"] = setsoundmode
        app.config.globalProperties["$querystring"] = querystring
        app.config.globalProperties["$exit"] = exit

    }
}